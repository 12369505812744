<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-form>
            <b-row>
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label="Chương trình đào tạo/Bồi dưỡng"
                  label-for="trainingSystem"
                >
                  <v-select
                    id="trainingSystem"
                    v-model="trainingSystemId"
                    label="name"
                    :clearable="false"
                    :options="trainingSystems"
                    :reduce="(option) => option.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <b-row class="mt-2">
            <b-col cols="12">

              <vue-good-table
                :columns="columns"
                :rows="dataSources"
                :pagination-options="paginationOptions"
                :line-numbers="true"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'action'">
                    <b-button
                      v-if="creatable"
                      v-b-tooltip.hover
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      title="Thực hiện tổng kết học kỳ"
                      placement="top"
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onCalculate(props.row)"
                    >
                      <feather-icon icon="FramerIcon" />
                    </b-button>
                  </span>
                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị 1 đến </span>
                      <b-form-select
                        v-model="itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import {
  BButton,
  BCard, BCol,
  BContainer, BForm,
  BFormGroup, BFormSelect,
  BOverlay, BPagination,
  BRow, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'CalculateCgpa',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormSelect,
    BPagination,
    BCol,
    BRow,
    BForm,
    BButton,
    BCard,
    BOverlay,
    vSelect,
    BContainer,
    BFormGroup,
    VueGoodTable,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      trainingSystemId: null,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Khóa',
          field: 'courseName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập khoá',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semesterName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập học kỳ',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Thời gian tính đầu tiên',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập thời gian',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thời gian tính gần nhất',
          field: 'updatedAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập thời gian',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Cán bộ thực hiện',
          field: 'createdBy',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập cán bộ',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'calculateCgpa/trainingSystems',
      dataSources: 'calculateCgpa/summarySemesters',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CALCULATE_CGPA)
    },
  },
  watch: {
    async trainingSystemId(val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getSummarySemesters({ trainingSystemId: val })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },

  beforeDestroy() {
    // Hủy kết nối và xóa các lắng nghe sự kiện trước khi component bị hủy
    this.sockets.unsubscribe('response_calculate_cgpa_from_socket')
  },
  mounted() {
    // Lắng nghe sự kiện từ server socket
    this.sockets.subscribe('response_calculate_cgpa_from_socket',  async (data) => {
      const dataParse = JSON.parse(data)
      let props = {}
      if (dataParse.status === 1) {
        props = {
          title: dataParse.message,
          icon: 'CheckIcon',
          variant: 'success',
        }
        await this.getSummarySemesters({ trainingSystemId: this.trainingSystemId })
      } else {
        props = {
          title: "Có lỗi xảy ra",
          icon: 'XCircleIcon',
          variant: 'danger',
        }
      }
      this.$toast({
        component: ToastificationContent,
        props,
      })
    })
  },

  async created() {
    this.$socket.emit('register_server_socket', `${this.user.accountId}-${this.user.orgCode}`)

    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({
        organizationId: this.user.orgId,
      })
      if (this.trainingSystems.length > 0) {
        this.trainingSystemId = this.trainingSystems[0].id
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'calculateCgpa/getTrainingSystemsByOrganizationId',
      getSummarySemesters: 'calculateCgpa/getSummarySemesters',
      calculateCgpaByCourseSemester: 'calculateCgpa/calculateCgpaByCourseSemester',
    }),
    async onCalculate(param) {
      const accountId = this.user.accountId
      const orgCode = this.user.orgCode
      const { courseSemesterId, logSummarySemesterId } = param
      this.isLoading = true
      try {
        const response = await this.calculateCgpaByCourseSemester({ courseSemesterId, logSummarySemesterId, accountId, orgCode })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getSummarySemesters({ trainingSystemId: this.trainingSystemId })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
